:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
  --jardim-color-1: #00875e;
  --jardim-color-2: #00a431;
  --text-opacity: 1;
  --border-opacity: 1;
  --bg-opacity: 1;
  --text-opacity: 1;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

body {
  color: var(--body-color);
}

header {
  position: relative;
  padding: 1.5rem 0 6rem;
}

nav {
  position: relative;
  max-width: 1280px;
  align-items: left;
  display: flex;
}

nav a {
  margin-right: 1rem;
}

h1, h2, h3, p {
  margin: 0;
}

h1 {
  font-size: 2.25rem;
  line-height: 1;
  letter-spacing: -0.025em;
  color: rgba(22,30,46,var(--text-opacity));
  margin: 0.25rem 0 0 0;
  font-weight: 800;
}

h2 {
  letter-spacing: -.025em;
  color: rgba(22,30,46,var(--text-opacity));
  margin-top: 0.25rem;
  line-height: 2.5rem;
  font-size: 2.25rem;
  font-weight: 800;

  span {
    color: var(--jardim-color-1);
  }
}

a {
  text-decoration: inherit;
  color: rgba(107,114,128,var(--text-opacity));
  font-weight: bold;
  background-color: transparent;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;

  &:hover {
    color: rgba(22,30,46,var(--text-opacity));
    text-decoration: underline;
  }
}

h1 a {
  color: #161e2e;
  color: rgba(22,30,46,var(--text-opacity));

  &:hover {
    color: var(--jardim-color-1);
  }
}

p {
  margin: 0.75rem 0 0 0;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

p a {
  color: var(--jardim-color-1);
  font-weight: normal;
}

input[type="email"] {
  appearance: none;
  margin: 0;
  font-family: inherit;
  color: inherit;
  overflow: visible;
  border: 1px solid #d2d6dc;
  border-radius: .375rem;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  width: 100%;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-color: rgba(164,202,254,var(--border-opacity));
    box-shadow: 0 0 0 3px rgba(118, 169, 250, .45)
  }
}

.signup {
  text-transform: none;
  background-image: none;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: rgba(0,135,94,var(--bg-opacity));
  border-color: transparent;
  border-radius: 0.375rem;
  border-width: 1px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  color: rgba(255, 255, 255, var(--text-opacity));
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  margin-top: 12px;

  &:hover {
    background-color: var(--jardim-color-2);
    background-color: rgba(0,164,49,var(--bg-opacity));
  }
}

.header-wrapper {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

main {
  position: relative;
  overflow: hidden;
  padding: 0 1rem 4rem 1rem;
  max-width: 1280px;
  margin: 0 auto;
}

footer {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem 0;
  color: rgba(107,114,128,var(--text-opacity));
}

form {
  margin-top: 0.75rem;

  > div {
    display: flex;
    margin-bottom: 1.25rem;
  }
}

.coming-soon {
  letter-spacing: .025rem;
  text-transform: uppercase;
  color: rgba(107,114,128,var(--text-opacity));
  font-size: .875rem;
  font-weight: 600;
}

.headline {
  p {
    color: rgba(107,114,128,var(--text-opacity));
    margin: 0.75rem 0 0 0;
    font-size: 1rem;
  }
}

.form-wrapper {
  margin-top: 1.25rem;

  p:first-of-type {
    font-weight: 500;
    font-size: 1rem;
    color: rgba(22,30,46,var(--text-opacity));
  }

  p:last-of-type {
    color: rgba(107,114,128,var(--text-opacity));
    margin-top: 0.75rem;
    line-height: 1.25rem;
    font-size: .875rem;
  }

  p.success {
    color: var(--jardim-color-1);
  }

  p.error {
    border: 1px solid #ff000054;
    padding: 1rem;
    background: #fff7f7;
    border-radius: 0.375rem;
    color: var(--action-color);
    margin: 1rem 0;
  }
}

.feature-highlights {
  display: grid;
  margin-top: 2.5rem;

  a {
    border: 1px solid #d2d6dc;
    display: grid;
    align-content: space-between;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1;
    padding: 2rem;
    padding-left: 2.5rem;
    padding-top: 4rem;
    text-align: left;

    span:first-of-type {
      height:  -webkit-fill-available;;
    }

    span:last-of-type {
      margin-top: 2rem;
      flex: 1 1 0%;
      justify-content: center;
      display: flex;
    }

    svg {
      width: 3rem;
      color: rgba(107,114,128,var(--text-opacity));
      height: 3rem;
    }

    &:hover {
      color: var(--jardim-color-1);

      svg {
        color: inherit;
      }
    }
  }
}

.feature {
  margin-top: 3.75rem;
  text-align: center;

  &:not(:first-child) {
    margin-top: 8rem;
  }

  h3 {
    margin-bottom: 2.5rem;
    line-height: 1;
    font-size: 2.25rem;
    font-weight: 700;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1.25rem;
    }
  }
}

.posts {
  box-sizing: border-box;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;

  article {
    -webkit-text-size-adjust: 100%;
    line-height: 1.5;
    border: 0 solid #d2d6dc;
    box-sizing: border-box;
    border-radius: .375rem;
    border-style: solid;
    border-width: 1px;
    margin-bottom: .75rem;
    color: rgba(0,135,94,var(--text-opacity));

    header {
      padding: 0;
      text-align: center;

      img {
        width: 100%;
        object-fit: cover;
      }

      a {
        font-size: .75rem;
        font-weight: normal;
      }

      h3 {
        line-height: 1.5;
        font-weight: 700;
        font-size: 1.5rem;
        letter-spacing: -.025em;
      }
    }

    section {
      padding: 0.75rem;

      p {
        line-height: 1.5;
        color: rgba(75,85,99,var(--text-opacity));
        font-size: initial;
      }

      a {
        text-align: center;
        text-decoration: inherit;
        background-color: rgba(0,135,94,var(--bg-opacity));
        flex: 1 1 0%;
        margin-top: .5rem;
        padding: .5rem;
        color: rgba(255, 255, 255, var(--text-opacity));
        width: auto;
        display: block;
      }
    }

  }
}

.post {

  .site--layout > header {
    padding-bottom: 0;
  }

  article {
    header {
      text-align: center;
      padding: 0 0 3rem 0;

      h2 {
        font-weight: 800;
        letter-spacing: -.025em;
        margin-bottom: 0.5rem;
        font-size: 2.25rem;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        border-bottom: 2px solid rgb(210, 214, 220);
        padding-bottom: 25px;
      }

      .metadata {
        letter-spacing: -.025em;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 800;

        .author {
          color: var(--jardim-color-1);
        }
      }

      img {
        border-radius: 50%;
        border-color: rgb(210, 214, 220);
        border-width: 2px;
        border-style: solid;
        margin-top: -50px;
      }
    }

    h3 {
      font-weight: 800;
      letter-spacing: -.025em;
      margin-bottom: 0.5rem;
      color: rgba(107,114,128,var(--text-opacity));
      font-size: 1.875rem;
    }

    h4 {
      color: rgba(159,166,178,var(--text-opacity));
      font-size: 1.5rem;
      font-weight: 800;
      letter-spacing: -.025em;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.25rem;
      letter-spacing: -.025em;
      margin-bottom: 0.75rem;
      line-height: 1.5;
    }

    hr {
      border: 0 solid #d2d6dc;
      box-sizing: border-box;
    }
  }
}

@media (min-width: 640px) {
  .header-wrapper {
    padding: 0 1.5rem;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1;
  }

  h2 {
    font-size: 4rem;
    line-height: 1;
  }

  input[type="email"] {
    flex: 1 1 0%;
  }

  button[type="submit"] {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0.75rem;
    width: auto;
  }

  main {
    padding: 0 1.5rem 4rem 1.5rem ;
  }

  footer {
    padding: 1 1.5rem;
  }

  .coming-soon {
    font-size: 1rem;
  }

  .headline {
    p {
      font-size: 1.25rem;
      margin-top: 1.25rem;
    }
  }

  .form-wrapper {
    margin: 0 0;
    max-width: 32rem;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .header-wrapper {
    flex-direction: column;
  }

  nav {
    margin-top: 1.25rem;
  }

  .posts {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
}

@media (min-width: 1024px) {
  body.with-background:before {
    background: url(/images/gardening-family.webp) 50%/cover no-repeat;
    background-position: 50%;
    background-repeat: no-repeat;
    content: "";
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    width: 43%;
  }

  h1 {
    font-size: 1.875rem;
  }

  h2 {
    font-size: 3rem;
  }

  .coming-soon {
    font-size: .875rem;
  }

  .headline {
    p {
      font-size: 1.125rem;
    }
  }

  .form-wrapper {
    margin: 1.25rem 0 0 0;
    max-width: 32rem;
    text-align: center;
  }

  .feature-highlights {
    grid-auto-flow: column;
    grid-template-columns: repeat(3,minmax(0,1fr));

    a:not(:last-of-type) {
      border-right-width: 0;
    }
  }
}

@media (min-width: 1280px) {
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 4rem;
  }

  .coming-soon {
    font-size: 1rem;
  }

  .headline {
    p {
      font-size: 1.25rem;
    }
  }

  .form-wrapper {
    text-align: left;
  }
}
